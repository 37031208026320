const resolveOptimizedImage = (fileName: string, size = '') => {
    if(!fileName){
        return ''
    }
    
    if(size === ''){
        return fileName.replace('.' + fileName.split('.').pop(), `.webp`)
    }

    return fileName.replace('.' + fileName.split('.').pop(), `-size-${size}.webp`)
}

export default resolveOptimizedImage;