import { useEffect, useState } from "react";

const useMediaQuery = (breakpoint?: 'md' | 'lg') => {
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const resolution = breakpoint === 'lg' ? '1023px' : '768px';
    
    useEffect(() => {
        const handleResize = (e: MediaQueryListEvent) => {
            setIsMobile(e.matches);
        };

        const mediaQuery = window.matchMedia(`(max-width: ${resolution})`);

        setIsMobile(mediaQuery.matches);

        mediaQuery.addEventListener('change', handleResize);

        return () => {
            mediaQuery.removeEventListener('change', handleResize);
        };
    }, []);

    return isMobile;
}

export default useMediaQuery;